import firebase from '../../firebase';
import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  menu: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS:
      return {
        ...state,
        menu: action.menu
      };
    case actionTypes.GET_PRODUCTS_FAILED:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          snackbar: true,
          message: 'Failed to retrieve menu. Please try again.'
        }
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'menu',
  storage: localforage,
  blacklist: ['menu']
};

export default persistReducer(persistConfig, reducer);
