import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Tooltip } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import L from '../../assets/img/L.png';
import M from '../../assets/img/M.png';
import S from '../../assets/img/S.png';
import XL from '../../assets/img/XL.png';
import XXL from '../../assets/img/XXL.png';
import HomeContext from '../../screens/home/context';

const useStyles = makeStyles({
  root: { marginTop: 50, marginBottom: 50 },
  image: {
    width: 300,
    height: 256
  },
  title: {
    fontSize: 20,
    color: 'black',
    fontFamily: 'Roboto Mono',
    padding: '10px 0px',
    whiteSpace: 'nowrap'
  },
  size: {
    backgroundColor: 'white',
    height: 30,
    border: '1px solid black',
    fontFamily: 'Roboto Mono',
    fontSize: 20,
    color: 'black',
    padding: '3px 15px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#989898' }
  },
  activeSize: {
    height: 30,
    backgroundColor: '#e4cc37',
    border: '1px solid black',
    fontFamily: 'Roboto Mono',
    fontSize: 20,
    color: 'black',
    padding: '3px 15px',
    cursor: 'pointer'
  },
  disabledSize: {
    height: 30,
    backgroundColor: '#212121',
    border: '1px solid black',
    fontFamily: 'Roboto Mono',
    fontSize: 20,
    color: 'black',
    padding: '3px 15px',
    cursor: 'pointer'
  },
  addToCartBtn: {
    backgroundColor: 'white',
    border: '2px solid black',
    fontFamily: 'Roboto Mono',
    fontSize: 18,
    color: 'black',
    padding: '3px 10px',
    cursor: 'pointer',
    textAlign: 'center'
  },
  addToCartBtnDisabled: {
    backgroundColor: 'grey',
    border: '2px solid black',
    fontFamily: 'Roboto Mono',
    fontSize: 18,
    color: 'black',
    padding: '3px 10px',
    cursor: 'pointer',
    textAlign: 'center'
  },
  sizes: {
    padding: '15px 5px'
  },
  sizeImage: {
    height: '100%'
  }
});

const sizeImages = {
  L,
  M,
  XL,
  S,
  XXL
};

export const AddToCartButton = ({ classes, onClick, quantity, disabled }) => {
  return (
    <AnimatePresence>
      {!disabled && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.5 }}
          className={classes.addToCartBtn}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={onClick}
        >
          {`ADD ${quantity} TO CART`}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const SizeButton = ({
  classes,
  size,
  active,
  onClick,
  stock,
  quantity
}) => {
  // if (quantity > stock) {
  //   return (
  //     <motion.div
  //       className={classes.disabledSize}
  //       whileHover={{ scale: 1.1 }}
  //       whileTap={{ scale: 0.9 }}
  //     >
  //       <img alt="size" className={classes.sizeImage} src={sizeImages[size]} />
  //     </motion.div>
  //   );
  // }
  if (stock === 0) {
    return (
      <motion.div
        className={classes.disabledSize}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <img alt="size" className={classes.sizeImage} src={sizeImages[size]} />
      </motion.div>
    );
  }
  return (
    <motion.div
      className={active ? classes.activeSize : classes.size}
      onClick={() => onClick(size)}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      <img alt="size" className={classes.sizeImage} src={sizeImages[size]} />
    </motion.div>
  );
};

export const QuantityButtons = ({ onClick, children, disabled }) => (
  <AnimatePresence>
    {!disabled && (
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0, scale: 0 }}
        onClick={onClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <Grid item container alignItems="center" justify="center">
          {children}
        </Grid>
      </motion.div>
    )}
  </AnimatePresence>
);

export const ProductImages = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1.1 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.1 }}
  >
    {children}
  </motion.div>
);

const Product = (props) => {
  const classes = useStyles();
  const { addToCart } = useContext(HomeContext);
  const { product } = props;
  const { imageUrl, title, price, sizes, docId, stock } = product;
  const [selectedSize, setSize] = useState('');
  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = () => {
    addToCart({
      title,
      price,
      selectedSize,
      quantity,
      imageUrl,
      docId
    });
    setSize('');
    setQuantity(1);
  };

  useEffect(() => {
    setQuantity(1);
  }, [selectedSize]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <ProductImages>
        <Grid item>
          <img
            src={imageUrl}
            alt={`${imageUrl}`}
            className={classes.image}
            // onLoad={() => console.log('loaded')}
          />
        </Grid>
      </ProductImages>

      <Grid item className={classes.title}>
        <span>{title}</span>
      </Grid>
      <Grid item className={classes.title}>
        <span>{price.displayCost}</span>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="center"
        className={classes.sizes}
        spacing={2}
      >
        {sizes.map((size) => {
          return (
            <Grid item key={size}>
              <SizeButton
                stock={stock[size]}
                onClick={(size) => setSize(size)}
                classes={classes}
                size={size}
                active={selectedSize === size}
                quantity={quantity}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="space-evenly"
        style={{ width: '100%' }}
      >
        <Grid item container xs={2} alignItems="center" justify="center">
          <QuantityButtons disabled={!selectedSize}>
            <RemoveCircleIcon
              style={{ fontSize: 40, color: '#071013' }}
              onClick={() => setQuantity(quantity <= 1 ? 1 : quantity - 1)}
            />
          </QuantityButtons>
        </Grid>
        <Grid item xs={7}>
          <AddToCartButton
            quantity={quantity}
            disabled={!selectedSize}
            classes={classes}
            onClick={() => handleAddToCart()}
          />
        </Grid>
        <Grid item container xs={2} alignItems="center" justify="center">
          <QuantityButtons disabled={!selectedSize}>
            {stock[selectedSize] === quantity ? (
              <Tooltip title={`ONLY ${stock[selectedSize]} LEFT`}>
                <AddCircleIcon style={{ fontSize: 40, color: '#071013' }} />
              </Tooltip>
            ) : (
              <AddCircleIcon
                style={{ fontSize: 40, color: '#071013' }}
                onClick={() => setQuantity(quantity + 1)}
              />
            )}
          </QuantityButtons>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Product;
