import {
  call,
  put,
  takeEvery,
  takeLatest,
  select,
  delay
} from 'redux-saga/effects';
import axios from 'axios';
import * as actionTypes from './actions';
import * as api from '../../api';
import * as selectors from './selectors';
import screenGlobal from '../global';

import * as screenOrderActions from '../order/actions';
import * as screenOrderSelectors from '../order/selectors';

function* infoValidationSaga(action) {
  yield delay(1000);
  try {
    const { step } = action;
    const contactFields = yield select(selectors.getContactFields);
    const shippingFields = yield select(selectors.getShippingFields);
    const updatedContactFields = { ...contactFields };
    const updatedShippingFields = { ...shippingFields };
    let hasError = false;

    const validateEmail = (email) => {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      if (reg.test(email) === false) {
        return false;
      } else {
        return true;
      }
    };

    Object.keys(updatedContactFields).forEach((field) => {
      if (field === 'firstName') {
        if (!updatedContactFields[field].value) {
          updatedContactFields[field].error = true;
          hasError = true;
        } else {
          updatedContactFields[field].error = false;
        }
      }
      if (field === 'lastName') {
        if (!updatedContactFields[field].value) {
          updatedContactFields[field].error = true;
          hasError = true;
        } else {
          updatedContactFields[field].error = false;
        }
      }
      if (field === 'email') {
        let emailValid = validateEmail(updatedContactFields[field].value);
        if (!emailValid) {
          updatedContactFields[field].error = true;
          hasError = true;
        } else {
          updatedContactFields[field].error = false;
        }
      }
      if (field === 'phoneNumber') {
        if (!updatedContactFields[field].value) {
          updatedContactFields[field].error = true;
          hasError = true;
        } else {
          updatedContactFields[field].error = false;
        }
      }
    });

    Object.keys(updatedShippingFields).forEach((field) => {
      if (field === 'address1') {
        if (!updatedShippingFields[field].value) {
          updatedShippingFields[field].error = true;
          hasError = true;
        } else {
          updatedShippingFields[field].error = false;
        }
      }
      if (field === 'city') {
        if (!updatedShippingFields[field].value) {
          updatedShippingFields[field].error = true;
          hasError = true;
        } else {
          updatedShippingFields[field].error = false;
        }
      }
      if (field === 'state') {
        if (!updatedShippingFields[field].value) {
          updatedShippingFields[field].error = true;
          hasError = true;
        } else {
          updatedShippingFields[field].error = false;
        }
      }
      if (field === 'zipCode') {
        if (!updatedShippingFields[field].value) {
          updatedShippingFields[field].error = true;
          hasError = true;
        } else {
          updatedShippingFields[field].error = false;
        }
      }
    });

    if (hasError) {
      yield put({
        type: actionTypes.INFO_VALIDATION_FAILED,
        contactFields: updatedContactFields,
        shippingFields: updatedShippingFields,
        errorMessage: 'FILL IN REQUIRED FIELDS'
      });
    } else {
      yield put({
        type: actionTypes.INFO_VALIDATION_SUCCESS,
        contactFields: updatedContactFields,
        shippingFields: updatedShippingFields,
        nextStep: step + 1
      });
    }
  } catch (error) {
    // yield put({
    //   type: actionTypes.INFO_VALIDATION_FAILED
    // });
  }
}

function* shippingValidationSaga(action) {
  yield delay(1000);
  try {
    const { step } = action;
    const selectedShipping = yield select(selectors.getSelectedShipping);
    let hasError = false;

    if (!selectedShipping) {
      hasError = true;
    }

    if (hasError) {
      yield put({
        type: actionTypes.SHIPPING_VALIDATION_FAILED,
        errorMessage: 'PICK A SHIPPING METHOD'
      });
    } else {
      yield put({
        type: actionTypes.SHIPPING_VALIDATION_SUCCESS,
        nextStep: step + 1
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SHIPPING_VALIDATION_FAILED,
      errorMessage: 'UNKWON ERROR. PLEASE TRY AGAIN.'
    });
  }
}

function* paymentValidationSaga(action) {
  yield delay(1000);
  try {
    // Source from payment field
    const { source } = action;

    // Cart selector
    const cart = yield select(screenOrderSelectors.getCart);
    const contactFields = yield select(selectors.getContactFields);
    const shippingFields = yield select(selectors.getShippingFields);
    const selectedShipping = yield select(selectors.getSelectedShipping);

    // Validate payment on backend

    const response = yield axios({
      method: 'POST',
      url:
        'https://us-central1-stickware-63ed5.cloudfunctions.net/processOrderWeb',
      data: {
        cart,
        source,
        contactFields,
        shippingFields,
        selectedShipping
      }
    });

    // If error with payment, put FAILED and error message.

    if (response.data.hasError) {
      if (response.data.error.type === 'StripeCardError') {
        throw new Error('There was an issue with the payment method.');
      } else if (response.data.error.type === 'api_connection_error') {
        throw new Error('There was an issue trying to access network.');
      } else if (response.data.error.type === 'validation_error') {
        throw new Error('There was a problem validating the payment provided.');
      } else {
        throw new Error(response.data.error);
      }
    }

    // If success payment, put SUCCESS and clear neccesary data.

    const newOrderSummary = response.data.newOrder;

    yield put({
      type: actionTypes.PAYMENT_VALIDATION_SUCCESS,
      newOrderSummary
    });
    yield put({
      type: screenOrderActions.RESET_ORDER_STATE_TO_INITIAL_STATE
    });
  } catch (error) {
    yield put({
      type: actionTypes.PAYMENT_VALIDATION_FAILED,
      errorMessage: error.message
    });
  }
}

function* mySaga() {
  yield takeLatest(actionTypes.INFO_VALIDATION, infoValidationSaga);
  yield takeLatest(actionTypes.SHIPPING_VALIDATION, shippingValidationSaga);
  yield takeLatest(actionTypes.PAYMENT_VALIDATION, paymentValidationSaga);
}

export default mySaga;
