import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import Context from '../../screens/home/context';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import './drawer.scss';

const useStyles = makeStyles({
  titleText: {
    color: 'white',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    cursor: 'pointer'
  },

  closeIcon: {
    color: 'white',
    fontSize: 20,
    cursor: 'pointer'
  },
  cartItemRoot: {
    height: 75
  },
  image: {
    width: 29,
    height: 25
  },
  cartTitleText: {
    color: 'white',
    fontFamily: 'Roboto Mono',
    fontSize: 13
  },
  cartText: {
    color: 'white',
    fontFamily: 'Roboto Mono',
    fontSize: 13,
    textAlign: 'center'
  },
  checkoutButton: {
    margin: 20,
    fontFamily: 'Roboto Mono',
    color: 'white'
  },
  cartItemContainer: {
    overflowY: 'scroll',
    width: '100%'
  }
});

const Header = () => {
  const classes = useStyles();
  const { toggleDrawer, cart } = useContext(Context);
  return (
    <Grid
      item
      container
      direction="row"
      justify="space-between"
      style={{ padding: 5 }}
    >
      <Grid item xs direction="row" justify="flex-start">
        <Typography className={classes.titleText}>
          {cart.length} {cart.length > 1 ? 'ITEMS' : 'ITEM'}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Typography className={classes.titleText} onClick={toggleDrawer}>
          CLOSE
        </Typography>
        <CloseIcon className={classes.closeIcon} onClick={toggleDrawer} />
      </Grid>
    </Grid>
  );
};

export const CartItem = ({ product }) => {
  const classes = useStyles();
  const { removeFromCart } = useContext(Context);
  const { title, price, quantity, imageUrl, selectedSize } = product;
  return (
    <Grid
      item
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      className={classes.cartItemRoot}
    >
      <Grid item xs={1} direction="row" justify="center" alignItems="center">
        <CloseIcon
          style={{ color: 'white', fontSize: 20 }}
          onClick={() => removeFromCart(product)}
        />
      </Grid>
      <Grid
        item
        container
        xs={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <img src={imageUrl} alt={`${imageUrl}`} className={classes.image} />
      </Grid>
      <Grid item xs={6} direction="row" justify="center" alignItems="center">
        <Typography className={classes.cartTitleText}>
          {title} ({selectedSize})
        </Typography>
      </Grid>
      <Grid item xs={1} direction="row" justify="center" alignItems="center">
        <Typography className={classes.cartText}>{quantity}</Typography>
      </Grid>
      <Grid item xs={2} direction="row" justify="center" alignItems="center">
        <Typography className={classes.cartText} style={{ textAlign: 'right' }}>
          ${price.displayCost}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CartItemAnimation = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, scale: 1 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 1 }}
  >
    {children}
  </motion.div>
);

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'black',
    backgroundColor: '#6ED4D3',
    fontFamily: 'Roboto Mono',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#6ED4D3'
    }
  }
}))(Button);

export default function TemporaryDrawer() {
  const classes = useStyles();
  const { drawerIsOpen, toggleDrawer, cart } = useContext(Context);

  if (cart.length === 0) {
    return (
      <React.Fragment>
        <Drawer anchor="right" open={drawerIsOpen} onClose={toggleDrawer}>
          <Grid container direction="column" style={{ height: '100%' }}>
            <Header />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              xs
              style={{
                color: 'white',
                fontFamily: 'Roboto Mono',
                fontSize: 13
              }}
            >
              YOUR CART IS EMPTY
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Drawer anchor="right" open={drawerIsOpen} onClose={toggleDrawer}>
        <Grid container xs direction="column">
          <Grid item>
            <Header />
          </Grid>
          <Grid
            item
            xs
            direction="column"
            id={'cartItemContainer'}
            className={classes.cartItemContainer}
          >
            {cart.map((product) => {
              return (
                <CartItemAnimation key={product.cartId} product={product}>
                  <CartItem product={product} />
                </CartItemAnimation>
              );
            })}
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Link
              to="/checkout"
              style={{ width: '100%', textDecoration: 'none', padding: 10 }}
            >
              <ColorButton
                fullWidth
                variant="contained"
                size="large"
                onClick={toggleDrawer}
              >
                CHECKOUT
              </ColorButton>
            </Link>
          </Grid>
        </Grid>
      </Drawer>
    </React.Fragment>
  );
}
