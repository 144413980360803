import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import Admin from './Admin';
import Context from './context';
import screenAdmin from '../../store/admin';
import Drawer from '../../components/admin/drawer';
import { Grid } from '@material-ui/core';
import * as screenAdminSelectors from '../../store/admin/selectors';
import ProgressLoader from '../../components/admin/progressOverlay';
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(141deg, #2cb5e8 0%, #6ED4D3 25%, #6ED4D3 51%, #0fb8ad 75%, #0B8A81 100%)',
    width: '100%'
  }
});

const Wrapper = ({ ...other }) => {
  const classes = useStyles();

  const { isSignedIn, adminLoading } = other;

  return (
    <div className={classes.root}>
      <Context.Provider value={{ ...other }}>
        <LoadingOverlay
          styles={{
            wrapper: {
              width: '100%',
              height: '100vh',
              position: 'fixed',
              overflow: adminLoading ? 'hidden' : 'auto'
            }
          }}
          active={adminLoading}
          spinner={<ProgressLoader />}
        >
          <Grid container>
            {isSignedIn && <Drawer />}

            <Admin />
          </Grid>
        </LoadingOverlay>
      </Context.Provider>
    </div>
  );
};

const mapState = (state) => ({
  errorMessage: state.admin.errorMessage,
  authFields: state.admin.authFields,
  adminLoading: state.admin.adminLoading,
  isSignedIn: state.admin.isSignedIn,
  user: state.admin.user,
  orders: screenAdminSelectors.getSortedOrders(state),
  grossTotal: screenAdminSelectors.getGrossTotal(state),
  products: screenAdminSelectors.getSortedProducts(state),
  navKey: state.admin.navKey
});

const mapDispatch = (dispatch) => ({
  setAuthFields: (fields) => {
    dispatch({ type: screenAdmin.actions.SET_AUTH_FIELDS, fields });
  },
  onAuthValidation: () => {
    dispatch({ type: screenAdmin.actions.AUTH_VALIDATION });
  },
  setOrders: (orders) => {
    dispatch({ type: screenAdmin.actions.SET_ORDERS, orders });
  },
  setNavKey: (navKey) => {
    dispatch({ type: screenAdmin.actions.SET_NAV_KEY, navKey });
  },
  setAdminProducts: (products) => {
    dispatch({ type: screenAdmin.actions.SET_ADMIN_PRODUCTS, products });
  },
  handleModifiedOrders: (orders) => {
    dispatch({ type: screenAdmin.actions.HANDLE_MODIFIED_ORDERS, orders });
  },
  updateOrderStatus: (payload) => {
    dispatch({
      type: screenAdmin.actions.UPDATE_ORDER_STATUS,
      payload
    });
  },
  updateProduct: (productFields, stockFields, docId, imageUrl) => {
    dispatch({
      type: screenAdmin.actions.UPDATE_PRODUCT,
      productFields,
      stockFields,
      docId,
      imageUrl
    });
  },
  handleModifiedProducts: (products) => {
    dispatch({ type: screenAdmin.actions.HANDLE_MODIFIED_PRODUCTS, products });
  },
  sendBulkEmail: (payload) => {
    dispatch({ type: screenAdmin.actions.SEND_BULK_EMAIL, payload });
  }
});

export default connect(mapState, mapDispatch)(Wrapper);
