import { put, takeLatest, select, delay } from 'redux-saga/effects';
import axios from 'axios';
import * as actionTypes from './actions';
import * as selectors from './selectors';
import firebase, { db } from '../../firebase';

function* authValidationSaga(action) {
  yield delay(1000);
  try {
    const authFields = yield select(selectors.getAuthFields);
    const updatedAuthFields = { ...authFields };
    let hasError = false;
    let authError = false;

    const validateEmail = (email) => {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
      if (reg.test(email) === false) {
        return false;
      } else {
        return true;
      }
    };

    Object.keys(updatedAuthFields).forEach((field) => {
      if (field === 'email') {
        let emailValid = validateEmail(updatedAuthFields[field].value);
        if (!emailValid) {
          updatedAuthFields[field].error = true;
          hasError = true;
        } else {
          updatedAuthFields[field].error = false;
        }
      }
      if (field === 'password') {
        if (!updatedAuthFields[field].value) {
          updatedAuthFields[field].error = true;
          hasError = true;
        } else {
          updatedAuthFields[field].error = false;
        }
      }
    });

    if (hasError) {
      return yield put({
        type: actionTypes.AUTH_VALIDATION_FAILED,
        authFields: updatedAuthFields,
        errorMessage: 'FILL IN REQUIRED FIELDS'
      });
    }

    try {
      yield firebase
        .auth()
        .signInWithEmailAndPassword(
          updatedAuthFields['email'].value,
          updatedAuthFields['password'].value
        );
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          return yield put({
            type: actionTypes.AUTH_VALIDATION_FAILED,
            authFields: updatedAuthFields,
            errorMessage: 'Wrong Password'
          });
        case 'auth/user-not-found':
          return yield put({
            type: actionTypes.AUTH_VALIDATION_FAILED,
            authFields: updatedAuthFields,
            errorMessage: 'User not found'
          });

        case 'auth/invalid-email':
          return yield put({
            type: actionTypes.AUTH_VALIDATION_FAILED,
            authFields: updatedAuthFields,
            errorMessage: 'Invalid Email address'
          });

        default:
          return yield put({
            type: actionTypes.AUTH_VALIDATION_FAILED,
            authFields: updatedAuthFields,
            errorMessage: 'Something went wrong.'
          });
      }
    }

    const user = firebase.auth().currentUser;

    yield put({
      type: actionTypes.AUTH_VALIDATION_SUCCESS,
      authFields: updatedAuthFields,
      user
    });
  } catch (error) {}
}

function* updateOrderStatusSaga(action) {
  try {
    const {
      status,
      docId,
      refundAmount,
      chargeId,
      total,
      contactFields,
      shippingFields,
      selectedShipping,
      cart,
      subTotal,
      tax,
      tracking
    } = action.payload;

    // Handle different status =>

    if (status === 'partial refund') {
      // Partially refund payment on backend

      const response = yield axios({
        method: 'POST',
        url:
          'https://us-central1-stickware-63ed5.cloudfunctions.net/partiallyRefundOrder',
        data: {
          docId,
          chargeId,
          refundAmount,
          contactFields,
          shippingFields,
          selectedShipping,
          subTotal,
          tax,
          total,
          cart
        }
      });

      // If error with refund, put FAILED and error message.

      if (response.data.hasError) {
        console.log(response.data.error);
        throw new Error(response.data.error);
      }
    } else if (status === 'shipped') {
      const response = yield axios({
        method: 'POST',
        url:
          'https://us-central1-stickware-63ed5.cloudfunctions.net/sendShippingEmail',
        data: {
          docId,
          chargeId,
          contactFields,
          shippingFields,
          selectedShipping,
          subTotal,
          tax,
          total,
          cart,
          tracking
        }
      });

      // If error with refund, put FAILED and error message.
      if (response.data.hasError) {
        console.log(response.data.error);
        throw new Error(response.data.error);
      }
    } else if (status === 'full refund') {
      console.log('status', status);
      console.log('chargeId', chargeId);
      console.log('refundAmount', refundAmount);
    } else {
      yield db.collection('orders').doc(docId).update({ status: status });
    }

    yield put({
      type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_ORDER_STATUS_FAILED,
      errorMessage: error.message
    });
  }
}

function* updateProductSaga(action) {
  try {
    const { productFields, stockFields, docId, imageUrl } = action;

    const updatedProduct = {
      docId: docId,
      enabled: productFields['enabled'].value,
      imageUrl: imageUrl,
      position: Number(productFields['position'].value),
      price: {
        displayCost: `$${productFields['price'].value}`,
        amount: Math.round(parseFloat(productFields['price'].value) * 100)
      },
      sizes: ['S', 'M', 'L', 'XL', 'XXL'],
      stock: stockFields,
      title: productFields['title'].value
    };

    db.collection('products').doc(docId).update(updatedProduct);

    yield put({
      type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS
    });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_ORDER_STATUS_FAILED,
      errorMessage: error.message
    });
  }
}

function* sendBulkEmail(action) {
  try {
    const { emailList } = action.payload;

    let htmlMessage = `<p style="text-align:center;">Hi, thank you for your order from <a href="http://stickwareapparel.com" target="_blank">stickwareapparel.com</a>!&nbsp;</p>
    <p style="text-align:center;">Orders will start going out tomorrow!</p>
    <p style="text-align:center;">Thank you guys so much for your patience and continous support!</p>
    <p style="text-align:center;"></p>
    <p style="text-align:center;"></p>
    <p style="text-align:center;">Please checkout the instagram <a href="http://www.instagram.com/stickwareapparel" target="_blank">@stickwareapparel</a> to see tees and for quiquer updates!</p>
    <p style="text-align:center;"></p>
    <p style="text-align:center;">Much Luv</p>
    <p style="text-align:center;"></p>`;

    const response = yield axios({
      method: 'POST',
      url:
        'https://us-central1-stickware-63ed5.cloudfunctions.net/sendBulkEmail',
      data: { emailList: emailList, htmlMessage }
    });

    // If error with sending bulk email, put FAILED and error message.

    if (response.data.hasError) {
      console.log(response.data.error);
      throw new Error(response.data.error);
    }

    yield put({
      type: actionTypes.SEND_BULK_EMAIL_SUCCESS
    });
  } catch (error) {
    yield put({
      type: actionTypes.SEND_BULK_EMAIL_FAILED,
      errorMessage: error.message
    });
  }
}

function* mySaga() {
  yield takeLatest(actionTypes.AUTH_VALIDATION, authValidationSaga);
  yield takeLatest(actionTypes.UPDATE_ORDER_STATUS, updateOrderStatusSaga);
  yield takeLatest(actionTypes.UPDATE_PRODUCT, updateProductSaga);
  yield takeLatest(actionTypes.SEND_BULK_EMAIL, sendBulkEmail);
}

export default mySaga;
