import React from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField } from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import clearCloud from '../../assets/img/clearCloud.png';
import clearCloud2 from '../../assets/img/clearCloud2.png';
import clearCloud3 from '../../assets/img/clearCloud3.png';
import clearCloud4 from '../../assets/img/clearCloud4.png';

const useStyles = makeStyles({
  clearCloud: {
    opacity: 0.2,
    position: 'relative',
    width: 300,
    right: '7%'
  },
  clearCloud1: {
    opacity: 0.1,
    position: 'relative',
    width: 180,
    right: '-10%'
  },
  clearCloud2: {
    opacity: 0.3,
    position: 'relative',
    width: 220,
    right: '-35%'
  },
  clearCloud3: {
    opacity: 0.2,
    position: 'relative',
    width: 400,
    right: '27%'
  },
  clearCloud4: {
    opacity: 0.2,
    position: 'relative',
    width: 380,
    right: '-17%'
  },
  clearCloud5: {
    opacity: 0.15,
    position: 'relative',
    width: 360,
    right: '2%'
  },
  clearCloud6: {
    opacity: 0.16,
    position: 'relative',
    width: 340,
    right: '-23%'
  },
  clearCloud7: {
    opacity: 0.25,
    position: 'relative',
    width: 320,
    right: '28%'
  },
  clearCloud8: {
    opacity: 0.2,
    position: 'relative',
    width: 300,
    right: '9%'
  },
  clearCloud9: {
    opacity: 0.134,
    position: 'relative',
    width: 280,
    right: '-8%'
  },
  clearCloud10: {
    opacity: 0.23,
    position: 'relative',
    width: 260,
    right: '15%'
  },
  clearCloud11: {
    opacity: 0.28,
    position: 'relative',
    width: 240,
    right: '-15%'
  },
  clearCloud12: {
    opacity: 0.21,
    position: 'relative',
    width: 220,
    right: '21%'
  },
  clearCloud13: {
    opacity: 0.123,
    position: 'relative',
    width: 200,
    right: '-23%'
  },
  clearCloud14: {
    opacity: 0.15,
    position: 'relative',
    width: 180,
    right: '31%'
  },
  clearCloud15: {
    opacity: 0.2,
    position: 'relative',
    width: 160,
    right: '-31%'
  },
  clearCloud16: {
    opacity: 0.1,
    position: 'relative',
    width: 140,
    right: '41%'
  },
  clearCloud17: {
    opacity: 0.1,
    position: 'relative',
    width: 120,
    right: '-36%'
  }
});

const BackgroundClouds = (props) => {
  const classes = useStyles();
  const { pages } = props;
  return (
    <React.Fragment>
      <ParallaxLayer offset={pages * 0.1} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.12} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud2}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.14} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud3}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.16} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud4}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.22} speed={0.4}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud5}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.28} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud6}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.3} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud7}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.32} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud8}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.34} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud9}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.36} speed={0.2}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud10}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.4} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud11}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.43} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud12}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.42} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud13}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.44} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud14}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.46} speed={0.2}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud15}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.48} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud11}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.51} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.52} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud2}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.54} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud3}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.56} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud4}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.58} speed={0.4}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud5}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.6} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud6}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.63} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud7}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.63} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud8}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.66} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud9}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.69} speed={0.2}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud10}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.71} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud11}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.73} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud12}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.75} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud13}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.77} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud14}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.79} speed={0.2}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud15}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.81} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud11}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.83} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.85} speed={0.3}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud2}
              alt="clearCloud"
              className={classes.clearCloud2}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.87} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud3}
              alt="clearCloud"
              className={classes.clearCloud3}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.89} speed={0.1}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud4}
              alt="clearCloud"
              className={classes.clearCloud4}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
      <ParallaxLayer offset={pages * 0.91} speed={0.4}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud5}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>

      <ParallaxLayer offset={pages * 0.93} speed={0.5}>
        <motion.div animate={{ scale: 1.3 }} transition={{ duration: 1.5 }}>
          <Grid container direction="column" alignItems="center">
            <img
              src={clearCloud}
              alt="clearCloud"
              className={classes.clearCloud6}
            />
          </Grid>
        </motion.div>
      </ParallaxLayer>
    </React.Fragment>
  );
};

export default BackgroundClouds;
