import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import screenMenu from './store/menu';
import screenAuth from './store/auth';
import screenGlobal from './store/global';
import screenOrder from './store/order';
import screenAdmin from './store/admin';
import screenCheckout from './store/checkout';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { all } from 'redux-saga/effects';
import localforage from 'localforage';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
  key: 'root',
  storage: localforage,
  blacklist: ['menu', 'auth', 'global', 'order', 'checkout', 'admin']
};

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  menu: screenMenu.reducer,
  auth: screenAuth.reducer,
  global: screenGlobal.reducer,
  order: screenOrder.reducer,
  checkout: screenCheckout.reducer,
  admin: screenAdmin.reducer
});

const persistedReducer = persistReducer(persistConfig, appReducer);

let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

let persistor = persistStore(store);

function* rootSaga() {
  yield all([
    screenMenu.sagas(),
    screenAuth.sagas(),
    screenGlobal.sagas(),
    screenOrder.sagas(),
    screenCheckout.sagas(),
    screenAdmin.sagas()
  ]);
}

sagaMiddleware.run(rootSaga);

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
