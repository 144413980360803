import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  authFields: {
    email: {
      label: 'EMAIL',
      error: false,
      value: '',
      required: true,
      type: 'email'
    },
    password: {
      label: 'PASSWORD',
      error: false,
      value: '',
      required: true,
      type: 'password'
    }
  },
  user: '',
  errorMessage: '',
  adminLoading: false,
  isSignedIn: false,
  orders: [],
  navKey: 'orders',
  products: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_FIELDS:
      return {
        ...state,
        authFields: action.fields
      };

    case actionTypes.AUTH_VALIDATION:
      return {
        ...state,
        adminLoading: true,
        errorMessage: ''
      };

    case actionTypes.AUTH_VALIDATION_FAILED:
      return {
        ...state,
        adminLoading: false,
        authFields: action.authFields,
        errorMessage: action.errorMessage
      };

    case actionTypes.AUTH_VALIDATION_SUCCESS:
      return {
        ...state,
        adminLoading: false,
        authFields: action.authFields,
        errorMessage: '',
        isSignedIn: true,
        user: action.user
      };

    case actionTypes.SET_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...action.orders]
      };

    case actionTypes.SET_ADMIN_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.products]
      };

    case actionTypes.SET_NAV_KEY:
      return {
        ...state,
        navKey: action.navKey
      };

    case actionTypes.HANDLE_MODIFIED_ORDERS:
      const modifiedIds = [
        ...new Set(action.orders.map((order) => order.docId))
      ];
      const updatedOrders = state.orders.filter(
        (product) => !modifiedIds.includes(product.docId)
      );
      return {
        ...state,
        orders: [...action.orders, ...updatedOrders]
      };

    case actionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        adminLoading: true
      };

    case actionTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        adminLoading: false
      };

    case actionTypes.UPDATE_ORDER_STATUS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        adminLoading: false
      };

    case actionTypes.UPDATE_PRODUCT:
      return {
        ...state,
        adminLoading: true
      };

    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        adminLoading: false
      };

    case actionTypes.UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
        adminLoading: false
      };

    case actionTypes.HANDLE_MODIFIED_PRODUCTS:
      const modifiedProductIds = [
        ...new Set(action.products.map((product) => product.docId))
      ];
      const updatedProducts = state.products.filter(
        (product) => !modifiedProductIds.includes(product.docId)
      );
      return {
        ...state,
        products: [...updatedProducts, ...action.products]
      };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'admin',
  storage: localforage,
  blacklist: ['adminLoading', 'errorMessage', 'orders', 'products']
};

export default persistReducer(persistConfig, reducer);
