import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function RadioButtonsGroup(props) {
  const { options, handleChange, value } = props;

  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange}>
        {options.map((option) => {
          return (
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={option.toUpperCase()}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
