import { createSelector } from 'reselect';

const checkoutState = (state) => state.checkout;

export const getContactFields = createSelector(
  checkoutState,
  (state) => state.contactFields
);
export const getShippingFields = createSelector(
  checkoutState,
  (state) => state.shippingFields
);

export const getSelectedShipping = createSelector(
  checkoutState,
  (state) => state.selectedShipping
);
