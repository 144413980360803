import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actions';
import * as api from '../../api';
import screenGlobal from '../global';

function* setMenu() {
  yield put({
    type: screenGlobal.actions.BEGIN_ACTION
  });
  try {
    const menu = yield api.getMenu();
    yield put({ type: actionTypes.SET_PRODUCTS, menu });
    yield put({
      type: screenGlobal.actions.FINISH_ACTION
    });
  } catch (e) {
    // handle error
    yield put({ type: actionTypes.GET_PRODUCTS_FAILED });
    yield put({
      type: screenGlobal.actions.OPEN_SNACKBAR,
      message: 'Failed to retrieve menu. Please try again.'
    });
    yield put({
      type: screenGlobal.actions.FINISH_ACTION
    });
  }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
  yield takeLatest(actionTypes.GET_PRODUCTS, setMenu);
}

export default mySaga;
