import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  TextField
} from '@material-ui/core';
import Context from './context';
import Orders from './Orders';
import Products from './Products';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  formTitle: {
    fontFamily: 'Roboto Mono',
    fontSize: 15,
    fontWeight: '400'
  }
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black'
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'black'
    },
    '& .MuiFilledInput-root': {
      '& fieldset': {
        borderColor: 'black'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.MuiFilledInput-focused fieldset': {
        borderColor: 'black'
      }
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    }
  }
})(TextField);

const FormComponent = (props) => {
  const { fields, onChange, title } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column">
      {title && (
        <Grid item style={{ padding: '10px 15px' }}>
          <Typography className={classes.formTitle}>{title}</Typography>
        </Grid>
      )}
      {Object.keys(fields).map((key) => {
        return (
          <Grid item style={{ padding: '5px 10px' }}>
            <CssTextField
              error={fields[key].error}
              type={fields[key].type}
              variant="filled"
              size={'small'}
              fullWidth
              placeholder={fields[key].label}
              value={fields[key].value}
              label={fields[key].label}
              inputProps={{
                style: {
                  fontFamily: 'Roboto Mono',
                  padding: fields[key].value ? '15px' : '15px'
                }
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  display: 'none',
                  fontFamily: 'Roboto Mono'
                }
              }}
              onChange={(e) => onChange(e.target.value, key)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#212121',
    fontFamily: 'Roboto Mono',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#212121'
    }
  }
}))(Button);

const Admin = (props) => {
  const {
    setAuthFields,
    authFields,
    errorMessage,
    onAuthValidation,
    adminLoading,
    isSignedIn,
    user,
    navKey
  } = useContext(Context);
  const classes = useStyles();
  const [errorCaption, setErrorCaption] = useState(errorMessage);

  useEffect(() => {
    setErrorCaption(errorMessage);
  }, [errorMessage]);

  const onAuthChange = (value, key) => {
    const updatedFields = { ...authFields };
    updatedFields[key] = { ...updatedFields[key], value };
    setAuthFields(updatedFields);
  };

  if (!isSignedIn || !user) {
    return (
      <Grid
        container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          <FormComponent
            title={''}
            fields={authFields}
            onChange={onAuthChange}
          />
          <br></br>
          {errorCaption && (
            <Grid
              CartItem
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: '0px 0px 10px 0px' }}
            >
              <ErrorIcon style={{ color: 'red' }} />
              <Typography className={classes.errorMessage}>
                {errorCaption}
              </Typography>
            </Grid>
          )}
          <ColorButton
            disabled={adminLoading}
            variant="contained"
            size="large"
            onClick={onAuthValidation}
          >
            SIGN IN
          </ColorButton>
          {adminLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Grid>
      </Grid>
    );
  }

  switch (navKey) {
    case 'orders':
      return (
        <Grid container>
          <Orders />
        </Grid>
      );
    case 'products':
      return (
        <Grid container>
          <Products />
        </Grid>
      );

    default:
      break;
  }
};

export default Admin;
