import firebase from '../../firebase';
import * as actionTypes from './actions';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const initialState = {
  alerts: { snackbar: false, message: '' },
  loading: false,
  drawerIsOpen: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SNACKBAR:
      return {
        ...state,
        alerts: { snackbar: true, message: action.message }
      };
    case actionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        alerts: { snackbar: false, message: '' }
      };
    case actionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        drawerIsOpen: !state.drawerIsOpen
      };
    case actionTypes.BEGIN_ACTION:
      return {
        ...state,
        loading: true
      };
    case actionTypes.FINISH_ACTION:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'global',
  storage: localforage,
  blacklist: ['loading', 'alerts', 'drawerIsOpen']
};

export default persistReducer(persistConfig, reducer);
