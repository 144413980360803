import React, { useState, useContext, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  CircularProgress,
  useMediaQuery,
  Typography
} from '@material-ui/core';
import { motion, AnimatePresence } from 'framer-motion';
import stickware from '../../assets/img/Stickware.png';
import stickCloud from '../../assets/img/stickcloud.png';
import signature from '../../assets/img/signature.png';
import cloud from '../../assets/img/cloud.png';
import logo from '../../assets/img/logo.png';
import clearCloud from '../../assets/img/clearCloud.png';
import clearCloud2 from '../../assets/img/clearCloud2.png';
import clearCloud3 from '../../assets/img/clearCloud3.png';
import clearCloud4 from '../../assets/img/clearCloud4.png';
import cartIcon from '../../assets/img/cartIcon.png';
import fullCartIcon from '../../assets/img/fullCartIcon.png';
import TMLogo from '../../assets/img/TMLogo.png';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import Product from '../../components/home/product';
import Context from './context';
import BackgroundClouds from '../../components/home/backgroundClouds';

const useStyles = makeStyles({
  root: {
    background:
      'linear-gradient(141deg, #2cb5e8 0%, #6ED4D3 25%, #6ED4D3 51%, #0fb8ad 75%, #0B8A81 100%)',
    height: '100%'
  },
  stickCloud: {
    position: 'relative',
    width: 350,
    right: 10
  },
  stickware: {
    position: 'relative',
    width: 300,
    top: 55
  },
  cloud: {
    position: 'relative',
    width: 200,
    left: -100,
    bottom: -33
  },
  cloudRight: {
    position: 'relative',
    width: 200,
    right: -100,
    bottom: -36
  },
  signature: {
    position: 'relative',
    width: 200,
    zIndex: -2
  },
  passCodeRoot: {
    height: '100vh',
    width: '100%',
    background:
      'linear-gradient(141deg, #2cb5e8 0%, #6ED4D3 25%, #6ED4D3 51%, #0fb8ad 75%, #0B8A81 100%)'
  },
  banner: {
    backgroundColor: '#131112',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    zIndex: 2
  },
  cartIconContainerMobile: {
    position: 'absolute',
    top: '3%',
    right: '5%',
    zIndex: 2
  },
  cartIconMobile: {
    position: 'relative',
    width: 25,
    padding: 0,
    marging: 0
  },
  cartIconContainer: {
    position: 'absolute',
    top: '3%',
    right: '3%',
    zIndex: 2
  },
  cartIcon: {
    position: 'relative',
    width: 35,
    padding: 0,
    marging: 0
  },
  cartCount: {
    position: 'relative',
    left: 19,
    bottom: 13,
    fontSize: 8,
    fontFamily: 'Roboto Mono',
    textAlign: 'center'
  }
});

const Home = () => {
  const { toggleDrawer, drawerIsOpen, cart, productList } = useContext(Context);

  // Enbled Products
  const enabledProducts = productList.filter((obj) => obj.enabled);
  const classes = useStyles();
  const [passCode, setPassCode] = useState('');
  const calculatePagesOut = (productLength) => {
    return parseFloat((440 + 475 * productLength) / window.innerHeight);
  };
  const isMobile = useMediaQuery('(max-width:1600px)');
  const addedValue = isMobile ? 0.6 : 0.5;

  const pageSize = useMemo(() => {
    return calculatePagesOut(enabledProducts.length) + addedValue;
  }, [enabledProducts, addedValue]);

  // // Inactive Home Until Next Drop
  // return (
  //   <Grid
  //     container
  //     className={classes.root}
  //     style={{ height: '100vh' }}
  //     justify="center"
  //     alignItems="center"
  //     direction="column"
  //   >
  //     <img src={logo} style={{ width: 200 }} />
  //     <Typography
  //       style={{
  //         fontFamily: 'Roboto Mono',
  //         letterSpacing: 1,
  //         fontSize: 14,
  //         fontWeight: '500'
  //       }}
  //     >
  //       DROP COMING 2021
  //     </Typography>
  //   </Grid>
  // );

  if (true) {
    return (
      <React.Fragment>
        <span
          className={
            isMobile
              ? classes.cartIconContainerMobile
              : classes.cartIconContainer
          }
          onClick={toggleDrawer}
        >
          {cart.length > 0 ? (
            <AnimatePresence>
              {!drawerIsOpen && (
                <motion.div
                  key="cart"
                  animate={{
                    scale: [2, 2, 2, 2],
                    rotate: [0, -30, -30, 0]
                  }}
                  transition={{
                    duration: 2,
                    ease: 'easeInOut',
                    times: [0, 0.25, 0.5, 1],
                    loop: Infinity,
                    repeatDelay: 1.8
                  }}
                >
                  <img
                    src={cart.length > 0 ? fullCartIcon : cartIcon}
                    alt="cartIcon"
                    className={
                      isMobile ? classes.cartIconMobile : classes.cartIcon
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          ) : (
            <AnimatePresence>
              {!drawerIsOpen && (
                <motion.div
                  key="cart"
                  initial={{ x: 0, opacity: 0, scale: 1 }}
                  animate={{ x: 0, opacity: 1, scale: 2 }}
                  transition={{ duration: 1 }}
                  exit={{ x: 100 }}
                >
                  {/* <span className={classes.cartCount}>{cart.length}</span> */}
                  <img
                    src={cart.length > 0 ? fullCartIcon : cartIcon}
                    alt="cartIcon"
                    className={
                      isMobile ? classes.cartIconMobile : classes.cartIcon
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </span>

        <Parallax
          pages={pageSize}
          scrolling={true}
          vertical
          className={classes.root}
        >
          <ParallaxLayer offset={0} speed={0.8}>
            <Grid container direction="column" alignItems="center">
              <img src={cloud} alt="cloud" className={classes.cloud} />
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.9}>
            <Grid container direction="column" alignItems="center">
              <img
                src={cloud}
                alt="cloudRight"
                className={classes.cloudRight}
              />
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.7}>
            <Grid container direction="column" alignItems="center">
              <img
                src={stickCloud}
                alt="stickCloud"
                className={classes.stickCloud}
              />
            </Grid>
          </ParallaxLayer>
          <ParallaxLayer offset={0} speed={0.4}>
            <Grid container direction="column" alignItems="center">
              <img
                src={stickware}
                alt="stickware"
                className={classes.stickware}
              />
            </Grid>
          </ParallaxLayer>

          <BackgroundClouds pages={pageSize} />

          <ParallaxLayer offset={0.25} speed={0}>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                direction="column"
                alignItems="center"
              >
                {enabledProducts.map((product) => {
                  if (product.enabled) {
                    return (
                      <Product
                        className={classes.productRoot}
                        product={product}
                      />
                    );
                  }
                })}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                direction="column"
                alignItems="center"
              >
                <a
                  target="_blank"
                  href="https://www.instagram.com/stickwareapparel/"
                  rel="noopener noreferrer"
                >
                  <img
                    src={signature}
                    alt="signature"
                    className={classes.signature}
                  />
                </a>
              </Grid>
            </Grid>
          </ParallaxLayer>
        </Parallax>
      </React.Fragment>
    );
  } else {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.passCodeRoot}
      >
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.textFieldContainer}
        >
          <img
            alt="TMLogo"
            src={TMLogo}
            style={{ width: 200, position: 'relative' }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={passCode}
            onChange={(e) => setPassCode(e.target.value)}
          />
        </Grid>
      </Grid>
    );
  }
};

export default Home;
